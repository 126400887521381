$thumb-size: 200px;
$wrap: 800px;

.post-excerpt-container {
  container-type: inline-size;
}

.post-excerpt {
  display: flex;
  margin: 20px 0;
  border-radius: var(--rounded);
  background: var(--background);
  overflow: hidden;
  box-shadow: var(--shadow);
}

.post-excerpt-image {
  position: relative;
  width: $thumb-size;
  flex-shrink: 0;
  // box-shadow: var(--shadow);
}

.post-excerpt-image img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-excerpt-text {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 30px;
  text-align: left;
}

.post-excerpt-text > * {
  margin: 0 !important;
}

.post-excerpt-text > a:first-child {
  width: 100%;
  font-weight: var(--semi-bold);
}

.post-excerpt-text > div {
  justify-content: flex-start;
}

.post-excerpt-text > p {
  width: 100%;
}

@container (max-width: #{$wrap}) {
  .post-excerpt {
    flex-direction: column;
  }

  .post-excerpt-image {
    width: unset;
    height: $thumb-size;
  }
}
